import { FlockLogo, TrackedIconButton } from '@flock/shared-ui'
import {
  Close,
  Download,
  Fullscreen,
  GridView,
  ZoomIn,
  ZoomOut,
} from '@mui/icons-material'
import { Box, CircularProgress, Drawer, Typography } from '@mui/material'
import React from 'react'
import { FullScreen } from 'react-full-screen'
import { Document, Page, pdfjs } from 'react-pdf'
import { DocumentRendererProps } from './documentRendererTypes'
import useDocumentRenderer from './useDocumentRenderer'

if (typeof window !== 'undefined' && 'Worker' in window) {
  pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`
}

const iconButtonStyles = {
  width: '36px',
  height: '36px',
  opacity: 0.8,
  backgroundColor: '#03341D',
  transition: 'opacity 0.5s background-color 0.5s',
  '&:hover': {
    opacity: 1,
    backgroundColor: '#004021',
  },
}

const DocumentRendererDesktop = (props: DocumentRendererProps) => {
  const {
    isMobile,
    documentPath,
    totalPages,
    currentPage,
    onDocumentLoadSuccess,
    incrementZoom,
    decrementZoom,
    zoomDecrementDisabled,
    zoomIncrementDisabled,
    zoom,
    currentPageHeight,
    fullScreenHandle,
    pageChooserOpen,
    togglePageChooser,
    onFirstPageLoad,
    onDownloadFile,
    onSelectPage,
    onLastChooserPageLoad,
    onLoadError,
  } = useDocumentRenderer(props)

  if (isMobile) {
    return null
  }

  return (
    <FullScreen handle={fullScreenHandle}>
      <Box width="100%" display="flex" flexDirection="column">
        <Box
          paddingX={{
            sm: '64px',
            md: '128px',
          }}
          height="72px"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            backgroundColor: '#03341D',
          }}
        >
          <FlockLogo width={132} height={38} color="gray1" />
          <Box display="flex" gap="16px" alignItems="center">
            <Typography color="gray1.main">
              {currentPage} / {totalPages}
            </Typography>
            <TrackedIconButton
              onClick={togglePageChooser}
              sx={iconButtonStyles}
            >
              <GridView color="gray1" />
            </TrackedIconButton>
            <Box
              width="1px"
              height="36px"
              sx={{
                backgroundColor: '#82878C',
              }}
            />
            <TrackedIconButton
              onClick={
                fullScreenHandle?.active
                  ? fullScreenHandle?.exit
                  : fullScreenHandle?.enter
              }
              sx={iconButtonStyles}
            >
              <Fullscreen color="gray1" />
            </TrackedIconButton>
            <TrackedIconButton
              disabled={zoomIncrementDisabled}
              onClick={incrementZoom}
              sx={iconButtonStyles}
            >
              <ZoomIn color={zoomIncrementDisabled ? 'gray5' : 'gray1'} />
            </TrackedIconButton>
            <TrackedIconButton
              disabled={zoomDecrementDisabled}
              onClick={decrementZoom}
              sx={iconButtonStyles}
            >
              <ZoomOut color={zoomDecrementDisabled ? 'gray5' : 'gray1'} />
            </TrackedIconButton>
            <Box
              width="1px"
              height="36px"
              sx={{
                backgroundColor: '#82878C',
              }}
            />
            <TrackedIconButton onClick={onDownloadFile} sx={iconButtonStyles}>
              <Download color="gray1" />
            </TrackedIconButton>
          </Box>
        </Box>
        <Box
          id="scrollArea"
          height="calc(100vh - 72px)"
          flexDirection="column"
          sx={{
            overflowY: 'scroll',
            backgroundColor: '#2B2E31',
          }}
        >
          <Document
            file={documentPath}
            onLoadSuccess={onDocumentLoadSuccess}
            onLoadError={onLoadError}
            loading={
              <Box
                width="100%"
                height="100%"
                display="flex"
                justifyContent="center"
                alignItems="center"
                paddingTop="64px"
              >
                <CircularProgress color="green4" />
              </Box>
            }
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              gap="16px"
              width="100%"
            >
              {Array.from({ length: totalPages }).map((_, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Box key={index} height={`${currentPageHeight}px`}>
                  <Box
                    sx={{
                      transform: `scale(${zoom})`,
                      transformOrigin: 'top center',
                    }}
                  >
                    <Page
                      className="react-pdf__MainPage"
                      pageNumber={index + 1}
                      scale={2}
                      loading={<></>}
                      onRenderSuccess={() => {
                        if (index === 0) {
                          onFirstPageLoad()
                        }
                      }}
                    />
                  </Box>
                </Box>
              ))}
            </Box>
          </Document>
        </Box>
      </Box>
      <Drawer
        anchor="right"
        open={pageChooserOpen}
        onClose={togglePageChooser}
        PaperProps={{
          sx: {
            backgroundColor: '#2B2E31',
            overflowX: 'hidden',
          },
        }}
      >
        <Box width="400px" display="flex" flexDirection="column" gap="16px">
          <Box display="flex" justifyContent="flex-end" pt="16px" mr="16px">
            <TrackedIconButton
              onClick={togglePageChooser}
              sx={{
                width: '36px',
                height: '36px',
                opacity: 0.8,
                transition: 'opacity 0.5s background-color 0.5s',
                '&:hover': {
                  opacity: 1,
                  backgroundColor: '#45494D',
                },
              }}
            >
              <Close color="gray1" />
            </TrackedIconButton>
          </Box>
          <Document
            file={documentPath}
            loading={
              <Box
                width="100%"
                height="100%"
                display="flex"
                justifyContent="center"
                alignItems="center"
                paddingTop="64px"
              >
                <CircularProgress color="green4" />
              </Box>
            }
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              gap="24px"
              width="100%"
              pb="48px"
              sx={{
                backgroundColor: '#2B2E31',
              }}
            >
              {Array.from({ length: totalPages }).map((_, index) => (
                <Box
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  id={`page-chooser-${index + 1}`}
                  height={`${currentPageHeight}px`}
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  gap="8px"
                  onClick={() => onSelectPage(index + 1)}
                  sx={{
                    height: 'fit-content',
                    opacity: currentPage === index + 1 ? 1 : 0.7,
                    cursor: 'pointer',
                    '& > div': {
                      width: '200px',
                      height: 'fit-content',
                      overflow: 'hidden',
                      border:
                        currentPage === index + 1
                          ? '4px solid #136D42'
                          : '4px solid transparent',
                    },

                    '& > div > canvas': {
                      width: '200px!important',
                      height: 'auto!important',
                      overflow: 'hidden',
                    },
                  }}
                >
                  <Page
                    pageNumber={index + 1}
                    renderAnnotationLayer={false}
                    renderTextLayer={false}
                    loading={<></>}
                    onRenderSuccess={() => {
                      if (index === totalPages - 1) {
                        onLastChooserPageLoad()
                      }
                    }}
                  />
                  <Typography color="gray1.main">{index + 1}</Typography>
                </Box>
              ))}
            </Box>
          </Document>
        </Box>
      </Drawer>
    </FullScreen>
  )
}

export default DocumentRendererDesktop
