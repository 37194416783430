import React from 'react'
import { SvgIcon, SvgIconProps, useTheme } from '@mui/material'

const FlockLogo = (props: SvgIconProps) => {
  // @ts-ignore
  const { color, fill, stroke, sx, width, height, ...otherProps } = props // eslint-disable-line
  const theme = useTheme()

  let selectedColor = color

  // @ts-ignore
  if (color && theme.palette[color]) {
    // @ts-ignore
    selectedColor = theme.palette[color].main
  }

  const fillStyle = fill || selectedColor || undefined
  

  const styles = {
    width: 'inherit',
    height: 'inherit',
    fill: 'inherit',
    ...sx,
  }

  const cleanedPaths = () => (<svg width={width || 120} height={height || 22} viewBox="0 0 120 22" fill="none">
<path d="M0 21.6016V0.436273H3.47717V21.6016H0ZM2.38866 12.5308V9.35596H13.1528V12.5308H2.38866ZM2.38866 3.61108V0.436273H13.6668V3.61108H2.38866Z" fill={fillStyle || '#03341D'}/>
<path d="M22.8839 21.6016V0.436273H26.361V21.6016H22.8839ZM25.2725 21.6016V18.4268H36.339V21.6016H25.2725Z" fill={fillStyle || '#03341D'}/>
<path d="M55.0609 21.904C53.5491 21.904 52.138 21.6218 50.8278 21.0574C49.5176 20.493 48.3686 19.7169 47.3809 18.7292C46.3932 17.7213 45.6171 16.5623 45.0527 15.252C44.5084 13.9216 44.2363 12.5005 44.2363 10.9887C44.2363 9.4769 44.5084 8.06588 45.0527 6.75564C45.6171 5.44541 46.3831 4.29643 47.3506 3.30871C48.3383 2.30084 49.4772 1.52478 50.7673 0.980525C52.0776 0.416116 53.4886 0.133911 55.0004 0.133911C56.5122 0.133911 57.9132 0.416116 59.2032 0.980525C60.5135 1.52478 61.6624 2.30084 62.6502 3.30871C63.6379 4.29643 64.4039 5.45549 64.9481 6.78588C65.5125 8.09612 65.7947 9.50714 65.7947 11.019C65.7947 12.5308 65.5125 13.9519 64.9481 15.2823C64.4039 16.5925 63.6379 17.7415 62.6502 18.7292C61.6826 19.7169 60.5437 20.493 59.2335 21.0574C57.9434 21.6218 56.5525 21.904 55.0609 21.904ZM55.0004 18.578C56.4316 18.578 57.6813 18.2555 58.7497 17.6104C59.8382 16.9654 60.6848 16.0785 61.2895 14.9497C61.9144 13.8007 62.2268 12.4804 62.2268 10.9887C62.2268 9.88005 62.0454 8.87218 61.6826 7.96509C61.3399 7.03785 60.8461 6.24163 60.201 5.57643C59.556 4.89108 58.79 4.36698 57.9031 4.00415C57.0363 3.64131 56.0687 3.4599 55.0004 3.4599C53.5894 3.4599 52.3396 3.78242 51.2511 4.42745C50.1828 5.05234 49.3361 5.92919 48.7113 7.058C48.1065 8.18682 47.8042 9.49706 47.8042 10.9887C47.8042 12.0974 47.9755 13.1254 48.3182 14.0728C48.681 15.0001 49.1749 15.7963 49.7998 16.4615C50.4448 17.1267 51.2108 17.6508 52.0977 18.0338C52.9846 18.3966 53.9522 18.578 55.0004 18.578Z" fill={fillStyle || '#03341D'}/>
<path d="M84.999 21.904C83.467 21.904 82.0459 21.6319 80.7357 21.0876C79.4456 20.5232 78.3168 19.7471 77.3492 18.7594C76.3817 17.7516 75.6358 16.5925 75.1117 15.2823C74.5876 13.9519 74.3256 12.5308 74.3256 11.019C74.3256 9.50714 74.5876 8.09612 75.1117 6.78588C75.6358 5.45549 76.3817 4.29643 77.3492 3.30871C78.3168 2.321 79.4456 1.54493 80.7357 0.980525C82.0459 0.416116 83.467 0.133911 84.999 0.133911C86.7124 0.133911 88.1939 0.416116 89.4437 0.980525C90.6935 1.54493 91.8021 2.31092 92.7697 3.27848L90.4113 5.6369C89.7864 4.95155 89.0204 4.41738 88.1133 4.03438C87.2264 3.65139 86.1883 3.4599 84.999 3.4599C83.9508 3.4599 82.9933 3.64131 82.1265 4.00415C81.2598 4.36698 80.5039 4.89108 79.8588 5.57643C79.2339 6.24163 78.7502 7.03785 78.4075 7.96509C78.0648 8.89234 77.8935 9.91029 77.8935 11.019C77.8935 12.1276 78.0648 13.1456 78.4075 14.0728C78.7502 15.0001 79.2339 15.8064 79.8588 16.4917C80.5039 17.1569 81.2598 17.6709 82.1265 18.0338C82.9933 18.3966 83.9508 18.578 84.999 18.578C86.2891 18.578 87.3776 18.3865 88.2645 18.0035C89.1716 17.6004 89.9376 17.0561 90.5624 16.3708L92.9209 18.7292C91.9533 19.7169 90.8144 20.493 89.5042 21.0574C88.1939 21.6218 86.6922 21.904 84.999 21.904Z" fill={fillStyle || '#03341D'}/>
<path d="M114.867 21.6016L105.07 10.5654L114.655 0.436273H119.1L108.517 11.4423L108.547 9.53738L119.402 21.6016H114.867ZM102.077 21.6016V0.436273H105.554V21.6016H102.077Z" fill={fillStyle || '#03341D'}/>
</svg>
)

  return (
    <SvgIcon component={cleanedPaths} inheritViewBox sx={styles} {...otherProps} />
  )
}

export default FlockLogo
