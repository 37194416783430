import { usePrevious } from '@flock/shared-ui'
import { UserEventType } from '@flock/utils'
import { useMediaQuery, useTheme } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { useFullScreenHandle } from 'react-full-screen'
import * as Sentry from '@sentry/gatsby'

import slugify from 'slugify'
import { track } from '../../../utils/analytics'
import { sleep } from '../../utils'

import {
  DocumentRendererProps,
  DocumentRendererPresentationalProps,
} from './documentRendererTypes'

const zoomIncrement = 0.2
const maxZoom = 1.2
const minZoom = 0.5

const useDocumentRenderer: (
  props: DocumentRendererProps
) => DocumentRendererPresentationalProps = (props: DocumentRendererProps) => {
  const { accountId } = props
  const [documentPath, setDocumentPath] = useState<string>('')
  const [totalPages, setTotalPages] = useState<number>(1)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const previousPage = usePrevious(currentPage, 1)
  const [zoom, setZoom] = useState<number>(0.5)
  const [zoomIncrementDisabled, setZoomIncrementDisabled] =
    useState<boolean>(false)
  const [zoomDecrementDisabled, setZoomDecrementDisabled] =
    useState<boolean>(false)

  const [loadError, setLoadError] = useState(false)

  const [originalPageHeight, setOriginalPageHeight] = useState<number>(0)
  const [currentPageHeight, setCurrentPageHeight] = useState<number>(0)

  const [pageChooserOpen, setPageChooserOpen] = useState<boolean>(false)
  const [pageDurationMap, setPageDurationMap] = useState<
    Record<number, number>
  >({})
  const [currentTime, setCurrentTime] = useState<number>(Date.now())

  const fullScreenHandle = useFullScreenHandle()

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'))

  const onLoadError = () => {
    setLoadError(true)
    Sentry.captureException(
      new Error(`Error loading document at path: ${documentPath}`)
    )
    window.open(documentPath, '_self')
  }

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setTotalPages(numPages)
  }

  const onDownloadFile = () => {
    window.open(documentPath, '_blank')
  }

  const incrementZoom = () => {
    const newZoom = zoom + zoomIncrement
    setZoom(newZoom)
    setZoomDecrementDisabled(false)
    if (newZoom >= maxZoom) {
      setZoomIncrementDisabled(true)
    }
    // Multiply by two because that's the original page scale
    setCurrentPageHeight(originalPageHeight * newZoom)
  }

  const decrementZoom = () => {
    const newZoom = zoom - zoomIncrement
    setZoom(newZoom)
    setZoomIncrementDisabled(false)
    if (newZoom <= minZoom) {
      setZoomDecrementDisabled(true)
    }
    setCurrentPageHeight(originalPageHeight * newZoom)
  }

  const togglePageChooser = () => {
    setPageChooserOpen(!pageChooserOpen)
  }

  const onFirstPageLoad = () => {
    const page = document.querySelector('.react-pdf__MainPage')
    if (page) {
      const pageHeight = page.getBoundingClientRect().height
      setOriginalPageHeight(pageHeight / zoom)
      setCurrentPageHeight(pageHeight)
      setCurrentPage(1)
    }
  }

  const onSelectPage = (page: number) => {
    setPageChooserOpen(false)
    const element = document.querySelector(
      `div.react-pdf__Page[data-page-number='${page}']`
    )
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const onLastChooserPageLoad = () => {
    const element = document.getElementById(`page-chooser-${currentPage}`)
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const updateDuration = useCallback(
    (justViewedPage: number) => {
      // Set the current time to the page duration for the current page
      const newTime = Date.now()
      const duration = Math.round((newTime - currentTime) / 1000)
      // Add the page duration to the map in seconds if the page duration is greater than 1 second if it exists.
      // Otherwise, set the page duration to the time
      if (duration > 5) {
        const newPageDurationMap = {
          ...pageDurationMap,
        }
        if (pageDurationMap[justViewedPage]) {
          newPageDurationMap[justViewedPage] += duration
        } else {
          newPageDurationMap[justViewedPage] = duration
        }
        setPageDurationMap(newPageDurationMap)
      }
      setCurrentTime(newTime)
    },
    [currentTime, pageDurationMap, setPageDurationMap, setCurrentTime]
  )

  useEffect(() => {
    // Get document path from url query params
    const isBrowser = typeof window !== 'undefined'

    const isWindow = isBrowser && window

    if (isWindow) {
      const url = new URL(window.location.href)
      const newDocumentPath = url.searchParams.get('documentPath')
      if (newDocumentPath) {
        setDocumentPath(newDocumentPath)
      }
    }

    return () => {}
  }, [])

  useEffect(() => {
    if (isMobile && documentPath) {
      window.location.href = documentPath
    }
  }, [isMobile, documentPath])

  useEffect(() => {
    const isBrowser = typeof window !== 'undefined'

    const isWindow = isBrowser && window

    if (isWindow) {
      // Get the url after the last slash
      const urlText = window.location.href
      const urlParts = urlText.split('/')
      const lastPart = urlParts[urlParts.length - 1]
      // Remove file extension from last part
      const fileName = lastPart.substring(0, lastPart.lastIndexOf('.'))

      const queryParams = new URLSearchParams(window.location.search)
      const documentURL = queryParams.get('documentPath')

      window.onbeforeunload = () => {
        let totalDuration = 0
        Object.values(pageDurationMap).forEach((duration) => {
          totalDuration += duration
        })

        const finalPageDurationMap = {
          ...pageDurationMap,
        }

        // Track the latest page
        const newTime = Date.now()
        const duration = Math.round((newTime - currentTime) / 1000)

        if (duration > 5) {
          if (finalPageDurationMap[currentPage]) {
            finalPageDurationMap[currentPage] += duration
          } else {
            finalPageDurationMap[currentPage] = duration
          }

          totalDuration += duration
        }

        track(`document-view.${slugify(fileName)}`, {
          type: 'document',
          actionType: UserEventType.DOCUMENT_DURATION,
          documentURL,
          totalDuration,
          pageDurationMap: finalPageDurationMap,
          accountId,
        })

        sleep(500)

        return undefined
      }
    }
    return () => {
      window.onbeforeunload = null
    }
  }, [pageDurationMap, currentTime, currentPage, accountId])

  useEffect(() => {
    if (totalPages > 0) {
      const options = {
        root: document.querySelector('#scrollArea'),
        rootMargin: '0px',
        threshold: 0.4,
      }

      const targets = document.querySelectorAll('.react-pdf__MainPage')

      targets.forEach((target) => {
        const callback = (entries: IntersectionObserverEntry[]) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              const newPageNumber = parseInt(
                entry.target.getAttribute('data-page-number') || '0',
                10
              )
              setCurrentPage(newPageNumber)
            }
          })
        }

        const observer = new IntersectionObserver(callback, options)
        observer.observe(target)
      })
    }
  }, [totalPages])

  useEffect(() => {
    updateDuration(previousPage)
  }, [currentPage])

  return {
    ...props,
    isMobile,
    isTablet,
    documentPath,
    totalPages,
    currentPage,
    incrementZoom,
    decrementZoom,
    zoom,
    onSelectPage,

    zoomIncrementDisabled,
    zoomDecrementDisabled,
    currentPageHeight,

    fullScreenHandle,

    pageChooserOpen,
    togglePageChooser,

    onFirstPageLoad,
    onDocumentLoadSuccess,
    onDownloadFile,
    onLastChooserPageLoad,

    loadError,
    onLoadError,
  }
}

export default useDocumentRenderer
